import React from "react";
import {
    ModalContainer,
    ModalContentContainer,
    ModalClose,
} from "./Model";
import { HiOutlineX } from "react-icons/hi";
import PromotionForm from "../Form/PromotionForm"

export default function PromotionModal({ onClose, isAuth, promotion }) {


    return <ModalContainer onClick={(e) => {
        if (e.target.id === "modal-container") {
            onClose(false)
        }
    }}>
        <ModalContentContainer className="text-center">
            <ModalClose onClick={() => onClose(false)}>
                <HiOutlineX
                    className="close-icon"
                    size={24}
                    color="#05C973"
                />
            </ModalClose>
            <PromotionForm isAuth={isAuth} promotion={promotion} />
        </ModalContentContainer>
    </ModalContainer>
}