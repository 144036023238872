import Header from "./components/Header";
import Sidebar from "./components/Sidebar"
import Main from "./main"
import MobileNavbar from "./components/MobileNavbar"
import ContactLineFloat from "./components/ContactLineFloat"
import MobileFooter from "./components/Footer/Mobile";
import { useLocation } from 'react-router-dom';
import { useSupport } from "./hook/SupportProvider";

const Layout = ({ children }) => {
  const { lineSupport } = useSupport();
  const location = useLocation();

  return (
    location.pathname.split('/')[1] === 'launch' ? <>{children}</> :
      <div className="flex flex-col h-screen">
        <Header />
        <MobileNavbar />
        <div className="flex flex-row flex-1 overflow-hidden">
          <Sidebar />
          <Main children={children} />
        </div>
        <ContactLineFloat line={lineSupport} />
        <MobileFooter />
      </div>
  )
};

export default Layout;