import { useState } from 'react'
import { TableContainer, Theader, TheadLabel, TBody, TR, TbodyLabel } from "./Table"

export default function AffiliateCommissionTypeTable() {
  const affiliates = [
    { type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { type: 'LOTTO', lv1: '0%', lv2: '0%' },
  ]
  const downlineAffiates = [
    { gameProvider: 'PGSlot', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'YGG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'CQNine', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'QT', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'RELAX', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'PG-Gaming', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'MPoker', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'HABANERO', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'YL', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'FG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    {
      gameProvider: 'AWC(KINGMAKER)',
      type: 'SLOT',
      lv1: '0.3%',
      lv2: '0.1%',
    },
    { gameProvider: 'KA', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'JOKER', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'GOLDY', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SIMPLEPLAY', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'LIVE22', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'MG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'AWC(JILI)', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'JDB', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'PRAGMATIC', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'AWC(SPADE)', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'AMEBA', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'EVOPLAY', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'GPS', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'RT', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'RSG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'PG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SABA-SPORT', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SABA', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'TIGER-SPORT', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'TIGER-BOXING', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'pharaoh888', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'AWC(E1SPORT)', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'AWC(SV388)', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SBO', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'UG', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'WW', type: 'SPORT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'WORLD', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'Star-DNA', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SA-CASINO', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    {
      gameProvider: 'PRAGMATIC-LIVE',
      type: 'CASINO',
      lv1: '0.3%',
      lv2: '0.1%',
    },
    { gameProvider: 'WM', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'DG', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'N2LIVE', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'ALLBET', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'AWC(SEXY)', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'EVOLUTION', type: 'CASINO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: '', type: 'LOTTO', lv1: '0%', lv2: '0%' },
  ]

  const [expands, setExpands] = useState([])

  const handleExpand = (type, isExpand) => {
    if (isExpand) {
      const removed = expands.filter((expand) => expand !== type)
      setExpands(removed)
    } else {
      setExpands((values) => [...values, type])
    }
  }

  return (
    <TableContainer>
      <Theader>
        <TheadLabel className='w-10'></TheadLabel>
        <TheadLabel className="w-2/4">ประเภท</TheadLabel>
        <TheadLabel className="w-2/4">ดาวน์ไลน์ LV1</TheadLabel>
        <TheadLabel className='w-2/4'>ดาวน์ไลน์ LV2</TheadLabel>
      </Theader>
      <TBody className="bg-gray">
      {affiliates.length > 0 &&
        affiliates.map((affiliate, index) => {
          const isExpand = expands.includes(affiliate.type)
          return (
            <>
            <TR key={index}>
              <TbodyLabel className='w-10'>
                {affiliate.type !== 'LOTTO' && (
                  <button
                    onClick={() => handleExpand(affiliate.type, isExpand)}
                    className="bg-[rgba(255,255,255,0.8)] text-black w-4 h-4 rounded-sm inline-flex justify-center items-center outline-none"
                  >
                    <span className="pb-1">{isExpand ? '-' : '+'}</span>
                  </button>
                )}
              </TbodyLabel>
              <TbodyLabel className='w-2/4'>{affiliate.type}</TbodyLabel>
              <TbodyLabel className='w-2/4'>{affiliate.lv1}</TbodyLabel>
              <TbodyLabel className='w-2/4'>{affiliate.lv2}</TbodyLabel>
            </TR>
            {isExpand && (
              <TR className='flex flex-col pl-10'>
                  <Theader>
                    <TheadLabel className='w-1/3'>เกม</TheadLabel>
                    <TheadLabel className='w-1/3'>ดาวน์ไลน์ LV1</TheadLabel>
                    <TheadLabel className='w-1/3'>ดาวน์ไลน์ LV2</TheadLabel>
                  </Theader>
                  <TBody>
                    {downlineAffiates.length > 0 &&
                      downlineAffiates
                      .filter((data) => data.type === affiliate.type)
                      .map((downlineAffiate) => {
                        return (
                          <TR key={downlineAffiate.gameProvider}>
                            <TbodyLabel className="w-1/3">{downlineAffiate.gameProvider}</TbodyLabel>
                            <TbodyLabel className="w-1/3">{downlineAffiate.lv1}</TbodyLabel>
                            <TbodyLabel className="w-1/3">{downlineAffiate.lv2}</TbodyLabel>
                          </TR>
                        )
                      })}
                  </TBody>
              </TR>
            )}
            </>
          )
        })}
      </TBody>
    </TableContainer>
  )
}
