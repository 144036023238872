import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { styled } from "styled-components";
import { BtnPrimarySm, BtnSecSm } from "./Button";
import { getBalance } from "../api/playerService";
import { useAuth } from "../hook/AuthProvider";
import { HiOutlineRefresh } from "react-icons/hi";
import Deposit from "./Model/Deposit";
import Withdraw from "./Model/Withdraw";
import HistoryModal from "./Model/History";
import ProfileModal from "./Model/Profile";
import MoonLoader from "react-spinners/MoonLoader";
import * as _ from "lodash";
import { moneyFormatWithDecimal } from "../utils/format";
export default function AuthHeader() {
  const { user } = useAuth();
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [balance, setBalance] = useState();

  /* Concept Polling. Use this field for get balance by Account */
  const timerIdRef = useRef(null);

  useEffect(() => {
    getAmount();
  }, []);

  useEffect(() => {
    if (user) {
      const pollingCallback = async () => {
        /**  Start polling logic here */
        /** Get Balance  */
        let res = await getBalance();

        if (!_.isNil(res)) {
          setBalance(res.Balance);
        }
      };

      const startPolling = () => {
        // Polling balance every 10 seconds and don't stop for now this feature
        timerIdRef.current = setInterval(pollingCallback, 10000);
      };
      startPolling();
    }
  }, [user]);

  const getAmount = async () => {
    const res = await getBalance();
    setBalance(res.Balance);
    setIsLoaded(false);
  };

  const handleRecheckBalance = async () => {
    setIsLoaded(true);
    getAmount();
  };

  return (
    <div>
      <div className="flex gap-2 items-center content-end">
        <BtnPrimarySm
          shadow="true"
          className="font-light text-sm h-8 hidden md:block"
          onClick={() => setIsHistoryModalOpen(true)}
        >
          ประวัติการเดิมพัน
        </BtnPrimarySm>
        <BtnSecSm
          shadow="true"
          className="mb-0 hidden md:block"
          onClick={() => setIsDepositModalOpen(true)}
        >
          <div className="flex flex-row gap-1 items-center">
            <img
              src="/asset/images/icon-menu/deposit.png"
              alt="deposit"
              className="w-4 h-4"
            />
            ฝากเงิน
          </div>
        </BtnSecSm>
        <BtnSecSm
          shadow="true"
          className="mb-0 hidden md:block"
          onClick={() => setIsWithdrawModalOpen(true)}
        >
          <div className="flex flex-row gap-1 items-center">
            <img
              src="/asset/images/icon-menu/withdraw.png"
              alt="withdraw"
              className="w-4 h-4"
            />
            ถอนเงิน
          </div>
        </BtnSecSm>

        <div className="md:flex md:items-center md:gap-2">
          <BalanceBox>
            <div className="w-4 h-4">
              <img src="/asset/images/coin.png" alt="coin" className="" />
            </div>
            <span className="text-sm text-wrap">
              {moneyFormatWithDecimal(balance)}
            </span>
            <div className="cursor-pointer" onClick={handleRecheckBalance}>
              {isLoaded ? (
                <MoonLoader
                  color={"#fff"}
                  size={14}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <HiOutlineRefresh color="#fff" size={16} />
              )}
            </div>
          </BalanceBox>
          {user && (
            <div
              className="font-bold text-sm text-right text-blue cursor-pointer"
              onClick={() => setIsProfileModalOpen(true)}
            >
              {user?.preferred_username}
            </div>
          )}
        </div>
        <button className="min-w-12 max-w-16">
          <img className="w-full" src="/asset/images/th.png" alt="th" />
        </button>
      </div>
      {isDepositModalOpen &&
        createPortal(
          <Deposit bankAccount uploadSlip onClose={setIsDepositModalOpen} />,
          document.body
        )}

      {isWithdrawModalOpen &&
        createPortal(
          <Withdraw onClose={setIsWithdrawModalOpen} balance={balance} />,
          document.body
        )}

      {isHistoryModalOpen &&
        createPortal(
          <HistoryModal onClose={setIsHistoryModalOpen} />,
          document.body
        )}

      {isProfileModalOpen &&
        createPortal(
          <ProfileModal user={user} onClose={setIsProfileModalOpen} />,
          document.body
        )}
    </div>
  );
}

const BalanceBox = styled.div.attrs({
  className: "flex gap-2 items-center font-light h-8 justify-end",
})(
  ({ theme }) => `
  border-radius: 0.5rem;
  color: ${theme.colors.text};
  background: ${theme.colors.dark[300]};
`
);
