import React from 'react'
import { styled } from 'styled-components'
import { TitleImgHead } from './Title'
import { BtnPrimarySm } from './Button'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hook/AuthProvider'
import { createPortal } from 'react-dom'
import Login from '../components/Model/Login'

export default function Section({ text, games, description, type }) {
  let { user } = useAuth()
  const navigate = useNavigate()
  
  const [isLoginModalOpen, setLoginModalOpen] = React.useState(false)

  const rungame = async (id) => {
    if (user) {
      const a = document.getElementById('launch-game')
      a.href = `/launch/${id}`
      a.click()
    } else {
      setLoginModalOpen(true)
    }
  }

  return (
    <>
      <Box>
        <TitleImgHead>
          <h1 className="text-center text-2xl">{text}</h1>
        </TitleImgHead>
        {description && (
          <h2 className="text-white text-center text-2xl">{description}</h2>
        )}
        <div className="grid grid-cols-2 gap-4 md:grid-cols-5 mt-5">
          <a id="launch-game" target="_blank" rel="noopener noreferrer" className="hidden"></a>
          {games &&
            games.map((obj, index) => {
              return (
                <Card key={index}>
                  <ImgCard>
                    <img
                      src={
                        type === 'slotGames'
                          ? obj.imageUrl
                          : `/asset/images/games/${obj.code}.png`
                      }
                      alt="game"
                      className="rounded-xl"
                    />
                    <CardHover>
                      <BtnPrimarySm
                        shadow="true"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        onClick={() => {
                          type === 'slot'
                            ? navigate(`/game/${obj.code}`)
                            : rungame(obj.id)
                        }}
                      >
                        เข้าเล่น
                      </BtnPrimarySm>
                    </CardHover>
                  </ImgCard>
                  {obj.name}
                </Card>
              )
            })}
        </div>
      </Box>
      {isLoginModalOpen &&
        createPortal(<Login onClose={setLoginModalOpen} />, document.body)}
    </>
  )
}

const Box = styled.div.attrs({
  className: 'container mx-auto px-4 lg:px-16 xl:px-36 pb-16 relative',
})``

const Card = styled.div.attrs({
  className: 'text-center text-white',
})`
  cursor: pointer;
`

const ImgCard = styled.div.attrs({
  className: 'mb-1',
})`
  position: relative;
`

const CardHover = styled.div.attrs({
  className: 'h-full w-full',
})`
  ${ImgCard}:hover & {
    display: block;
    opacity: 1;
  }
  opacity: 0;
  position: absolute;
  transition: all 0.2s;
  top: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 12px;
`
