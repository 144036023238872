import React from 'react'
import { ModalContainer, ModalContentContainer, ModalClose } from './Model'
import { HiOutlineX } from 'react-icons/hi'
import { styled } from 'styled-components'
import HistoryTable from '../Teble/HistoryTable'
import ContactSupport from '../Footer/ContactSupport'

export default function HistoryModal({ onClose }) {
  return (
    <ModalContainer
      onClick={(e) => {
        if (e.target.id === 'modal-container') {
          onClose(false)
        }
      }}
    >
      <ModalContentContainer className="text-center">
        <ModalClose onClick={() => onClose(false)}>
          <HiOutlineX className="close-icon" size={24} color="#05C973" />
        </ModalClose>
        <h2 className="mb-5 text-white text-center font-bold text-xl">
          ประวัติการเดิมพัน
        </h2>
        <Text>ผลแพ้ชนะ</Text>
        <HistoryTable title={'ถอน'} />
        <hr className="text-white mt-2" />
        <ContactSupport />
      </ModalContentContainer>
    </ModalContainer>
  )
}

const Text = styled.div.attrs({
  className: 'text-center text-sm',
})(
  ({ theme }) => `
    color: ${theme.colors.green};
    margin-top: 1rem;
`
)
