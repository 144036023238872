import MoonLoader from "react-spinners/MoonLoader";
import { formatDateTime } from "../../utils/format";
import _ from "lodash";

export default function AffiliateDownlineTable({ downlines = [], loading }) {
  return (
    <table className="w-full overflow-hidden">
      <thead className="bg-gradient-to-r from-blue to-green py-4">
        <tr className="text-black">
          <th>#</th>
          <th className="py-2">ดาวน์ไลน์</th>
          <th>AFF</th>
          <th>วันสร้าง</th>
        </tr>
      </thead>
      <tbody className="bg-gray">
        {loading ? (
          <tr>
            <td colSpan={4} className="py-2">
              <div className="flex justify-center">
                <MoonLoader color={"#fff"} size={18} className="text-center" />
              </div>
            </td>
          </tr>
        ) : !_.isNil(downlines) && downlines.length > 0 ? (
          downlines.map((downline, index) => {
            return (
              <tr key={index} className="text-center font-bold">
                <td className="w-10 text-center">{index + 1}</td>
                <td className="py-2">{downline.username}</td>
                <td>{downline.level}</td>
                <td>{formatDateTime(downline.created_at)}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={4} className="py-2">
              ไม่มีข้อมูล
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
