import React from 'react';
import { HiChevronDoubleRight } from "react-icons/hi";
import styled from 'styled-components';

export default function Stepper({ steps, currentStep, numberOfSteps }) {
    const activeColor = (index) => currentStep >= index ? true : false
    const isFinalStep = (index) => index === numberOfSteps - 1

    return (
        <div className="flex items-center gap-4 justify-center">
            {steps.map((obj, index) => (
                <React.Fragment key={index}>
                    <StepperWrap>
                        <StepperItem $isAcitve={activeColor(index)}>{index + 1}</StepperItem>
                        <StepperName $isAcitve={activeColor(index)}>{obj}</StepperName>
                    </StepperWrap>
                    {isFinalStep(index) ? null : <HiChevronDoubleRight color='#fff' />}
                </React.Fragment>
            ))}
        </div>
    )
}

const StepperWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
`;

const StepperItem = styled.div`
    border: 2px solid ${props => !!props.$isAcitve ? `transparent` : props.theme.colors.white};
    background: ${props => !!props.$isAcitve ? `linear-gradient(90deg, ${props.theme.colors.blue}, ${props.theme.colors.green})` : `transparent`};
    color: ${props => !!props.$isAcitve ? `black` : props.theme.colors.white};
    height: 48px;
    width: 48px;
    border-radius:50%;
    line-height: 44px;
    font-size: 20px;
    margin: auto;
`;

const StepperName = styled.div`
    color: ${props => !!props.$isAcitve ? props.theme.colors.green : props.theme.colors.white};
    font-size: 14px;
    padding-top: 8px;
`;
