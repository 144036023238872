import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { styled } from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../hook/AuthProvider'
import { getBalance } from '../../api/playerService'
import Deposit from '../Model/Deposit'
import Withdraw from '../Model/Withdraw'
import HistoryModal from '../Model/History'
import RegisterModal from '../Model/Register'

export default function MobileFooter() {
  let { user } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false)
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false)
  const [balance, setBalance] = useState()
  const menu = [
    {
      name: 'โปรโมชั่น',
      pathname: '/promotion',
    },
    {
      name: 'สมัครสมาชิค',
      pathname: '/register',
    },
    {
      name: 'หารายได้',
      pathname: '/affiliate',
    },
  ]

  useEffect(() => {
    if (user) {
      getAmount()
    }
  }, [user])

  const getAmount = async () => {
    const res = await getBalance()
    setBalance(res.Balance)
  }

  return (
    <Box>
      {user ? (
        <>
          <Menu onClick={() => setIsDepositModalOpen(true)}>
            <img
              src={`/asset/images/icon-menu/deposit.png`}
              alt="icon menu"
              className="w-1/2 m-auto"
            />
            <span className="text-sm text-white mb-3 whitespace-nowrap">
              ฝาก
            </span>
          </Menu>
          <Menu onClick={() => setIsWithdrawModalOpen(true)}>
            <img
              src={`/asset/images/icon-menu/withdraw.png`}
              alt="icon menu"
              className="w-1/2 m-auto pl-1"
            />
            <span className="text-sm text-white mb-3 whitespace-nowrap">
              ถอน
            </span>
          </Menu>
          <Menu onClick={() => setIsHistoryModalOpen(true)}>
            <img
              src={`/asset/images/icon-menu/history.png`}
              alt="icon menu"
              className="w-1/2 m-auto"
            />
            <span className="text-sm text-white mb-3 whitespace-nowrap">
              ประวัติการเล่น
            </span>
          </Menu>
          <Menu onClick={() => navigate('/promotion')}>
            <img
              src={`/asset/images/icon-menu/promotion.png`}
              alt="icon menu"
              className="w-1/2 m-auto"
            />
            <span className="text-sm text-white mb-3 whitespace-nowrap">
              โปรโมชั่น
            </span>
          </Menu>
          <Menu onClick={() => navigate('/affiliate')}>
            <img
              src={`/asset/images/icon-menu/affiliate.png`}
              alt="icon menu"
              className="w-1/2 m-auto"
            />
            <span className="text-sm text-white mb-3 whitespace-nowrap">
              หารายได้
            </span>
          </Menu>
        </>
      ) : (
        menu.map((obj, index) => {
          return (
            <Menu
              key={index}
              onClick={() =>
                index === 1
                  ? setRegisterModalOpen(true)
                  : navigate(obj.pathname)
              }
              className={location.pathname === obj.pathname ? 'active' : ''}
            >
              <img
                src={`/asset/images/icon-menu/${obj.pathname}.png`}
                alt="icon menu"
                className="w-10 m-auto"
              />
              <span className="text-sm text-white mb-3">{obj.name}</span>
            </Menu>
          )
        })
      )}

      {isDepositModalOpen &&
        createPortal(
          <Deposit bankAccount uploadSlip onClose={setIsDepositModalOpen} />,
          document.body
        )}
      {isWithdrawModalOpen &&
        createPortal(
          <Withdraw onClose={setIsWithdrawModalOpen} balance={balance} />,
          document.body
        )}
      {isHistoryModalOpen &&
        createPortal(
          <HistoryModal onClose={setIsHistoryModalOpen} />,
          document.body
        )}
      {isRegisterModalOpen &&
        createPortal(
          <RegisterModal onClose={setRegisterModalOpen} />,
          document.body
        )}
    </Box>
  )
}

const Box = styled.div.attrs({
  className:
    'w-full block md:hidden flex justify-around px-2 pt-2 fixed inset-x-0 bottom-0',
})(
  ({ theme }) => `
  background:  ${theme.colors.dark[600]};
  border-top: 1px solid ${theme.colors.green};
`
)

const Menu = styled.div.attrs({
  className: 'flex flex-col text-center cursor-pointer relative',
})``
