import { useSupport } from "../../hook/SupportProvider";

export default function ContactSupport() {
    const { lineSupport, } = useSupport()
    return (
        <div className="mt-4">
            <div className="text-center text-white text-xs">พบปัญหา</div>
            <div>
                <a
                    href={lineSupport.line_url}
                    target="_blank"
                    className="text-center text-blue text-xs underline"
                    rel="noreferrer"
                >
                    ติดต่อฝ่ายบริการลูกค้า
                </a>
            </div>
        </div>
    );
}