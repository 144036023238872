import { styled } from 'styled-components'

const ModalContainer = styled.div.attrs({
  id: 'modal-container',
})`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  transition: all 0.3s ease-in-out;
  overflow: auto;
`

const ModalContentContainer = styled.div(
  ({ theme }) => `
  background:  ${theme.colors.dark[300]};
  border-radius: 8px;
  width: 35%;
  height: fit-content;
  padding: 2rem 2rem 2rem;
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media screen and (max-width: 1024px) {
    width: 40%;
  }

  @media screen and (max-width: 769px) {
    width: 50%;
  }

  @media screen and (max-width: 425px) {
    width: 90%;
  }
`
)

const ModalContentContainerColor = styled(ModalContentContainer).attrs({
  className: '',
})(
  ({ theme }) => `
  background: ${theme.colors.dark[500]}
`
)

const ModalBodyContainer = styled.div`
  padding: 1.5rem 1.5rem;
`

const ModalFooterContainer = styled.div(
  ({ theme }) => `
  text-align: center;

  a {
    color: ${theme.colors.textLight};
  }
`
)

const ModalClose = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`

export {
  ModalContainer,
  ModalContentContainer,
  ModalBodyContainer,
  ModalFooterContainer,
  ModalClose,
  ModalContentContainerColor,
}
