import { styled } from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'

export default function MobileNavbar() {
  const navigate = useNavigate()
  const location = useLocation()

  const menu = [
    {
      name: 'หน้าหลัก',
      pathname: '/',
    },
    {
      name: 'กีฬา',
      pathname: '/sport',
    },
    {
      name: 'คาสิโน',
      pathname: '/casino',
    },
    {
      name: 'สล็อต',
      pathname: '/slot',
    },
    {
      name: 'หวย',
      pathname: '/lotto',
    },
    {
      name: 'วงล้อ',
      pathname: '/spin',
    },
  ]

  return (
    <Box>
      {menu.map((obj, index) => {
        return (
          <Menu
            key={index}
            onClick={() => navigate(obj.pathname)}
            className={location.pathname === obj.pathname ? 'active' : ''}
          >
            <img
              src={`/asset/images/icon-menu/${
                obj.pathname === '/' ? 'home' : obj.pathname
              }.png`}
              alt="icon menu"
              className="w-[65%] m-auto"
            />
            <span className="text-xs text-white mb-2 whitespace-nowrap">
              {obj.name}
            </span>
            <Active />
          </Menu>
        )
      })}
    </Box>
  )
}

const Box = styled.div.attrs({
  className: 'w-full block md:hidden grid grid-cols-6 justify-center pt-2',
})(
  ({ theme }) => `
  background:  ${theme.colors.dark[600]};
  border-bottom: 1px solid ${theme.colors.green};
`
)

const Menu = styled.div.attrs({
  className: 'flex flex-col text-center cursor-pointer relative',
})``

const Active = styled.div.attrs({
  className: 'h-full w-full',
})(
  ({ theme }) => `
  ${Menu}.active & {
    display: block;
    opacity: 1;
  }
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: all 0.2s;
    top: 0;
    left: 0;
    display: none;
    background: linear-gradient(10deg, ${theme.colors.green} -50%, rgba(0, 0, 0, 0) 85%);
    z-index: 1;
`
)
