import React from 'react'
import { createPortal } from 'react-dom'
import { styled } from 'styled-components'
import { ButtonSec, ButtonPrimary } from './Button'
import Login from './Model/Login'
import RegisterModal from './Model/Register'
import { useLocation } from 'react-router-dom'
import AuthHeader from './AuthHeader'
import { useAuth } from '../hook/AuthProvider'

export default function Header() {
  const location = useLocation()
  let { user } = useAuth()
  const [isLoginModalOpen, setLoginModalOpen] = React.useState(false)
  const [isRegisterModalOpen, setRegisterModalOpen] = React.useState(false)

  React.useEffect(() => {
    const pathname = location.pathname.split('/')
    if (pathname[1] !== undefined && pathname[1] === 'register')
      setRegisterModalOpen(true)
    if (pathname[1] !== undefined && pathname[1] === 'login')
      setLoginModalOpen(true)
  }, [location.pathname])

  return (
    <Head>
      <Logo src="/asset/images/logo.png" alt="logo" />
      {user ? (
        <AuthHeader />
      ) : (
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row items-center">
          <ButtonSec
            shadow="true"
            className="text-lg me-2 w-44 hidden md:block"
            onClick={() => setRegisterModalOpen(true)}
          >
            สมัครสมาชิก
          </ButtonSec>
          <div className="flex items-center">
            <ButtonPrimary
              shadow="true"
              className="text-lg w-44"
              onClick={() => setLoginModalOpen(true)}
            >
              เข้าสู่ระบบ
            </ButtonPrimary>
            <button className="min-w-10">
              <img className="w-full" src="/asset/images/th.png" alt="th" />
            </button>
          </div>
          {isLoginModalOpen &&
            createPortal(<Login onClose={setLoginModalOpen} />, document.body)}
          {isRegisterModalOpen &&
            createPortal(
              <RegisterModal onClose={setRegisterModalOpen} />,
              document.body
            )}
        </div>
      )}
    </Head>
  )
}

const Head = styled.div.attrs({
  className: 'flex flex-row justify-between items-center py-1 px-2 md:px-6',
})(
  ({ theme }) => `
  background: ${theme.colors.dark[500]};
  border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, ${theme.colors.blue}, ${theme.colors.green});
    border-image-slice: 1;
    width:100%;
    font-family: 'thonburi';
`
)

const Logo = styled.img.attrs({
  className: 'w-24 z-10',
})`
  @media only screen and (max-width: 769px) {
    margin-bottom: 0px;
  }
`
