import React from "react";
import {
  ModalContainer,
  ModalContentContainer,
  ModalClose,
} from "./Model";
import { HiOutlineX } from "react-icons/hi";
import LoginForm from "../Form/LoginForm";

export default function Login({ onClose }) {
  return <ModalContainer onClick={(e) => {
    if (e.target.id === "modal-container") {
      onClose(false)
    }
  }}>
    <ModalContentContainer className="text-center">
      <ModalClose onClick={() => onClose(false)}>
        <HiOutlineX
          className="close-icon"
          size={24}
          color="#05C973"
        />
      </ModalClose>
      <h2 className="mb-10 text-white text-center font-bold text-xl">เข้าสู่ระบบ</h2>
      <LoginForm />
    </ModalContentContainer>
  </ModalContainer>
}
