import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { HiOutlineX } from 'react-icons/hi'
import { RiUserFill } from 'react-icons/ri'
import { ModalClose, ModalContainer, ModalContentContainer } from './Model'
import { BaseButton } from '../Button'
import { useAuth } from '../../hook/AuthProvider'
import { getPlayerBankAccount } from '../../api/playerService.js'
import BankAccount from '../BankAccount'
import ChangePassword from './ChangePassword'

export default function ProfileModal({ user, onClose }) {
  const auth = useAuth()
  const [userBankAct, setUserBankAct] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getBankAccount()
  }, [])
  
  const getBankAccount = async () => {
    const res = await getPlayerBankAccount()
    setUserBankAct(res)
  }

  return (
    <ModalContainer
      onClick={(e) => {
        if (e.target.id === 'modal-container') {
          onClose(false)
        }
      }}
    >
      <ModalContentContainer className="text-center justyfy-center">
        <ModalClose onClick={() => onClose(false)}>
          <HiOutlineX className="close-icon" size={24} color="#05C973" />
        </ModalClose>
        <h2 className="mb-5 text-white text-center font-bold text-xl">
          บัญชีของฉัน
        </h2>
        <div className="text-center">
          <RiUserFill className="text-gray text-[3rem]" />
        </div>
        <div className="text-white my-4">{user?.preferred_username}</div>
        <BaseButton
        className="text-blue underline mb-3"
        onClick={() => setIsModalOpen(true)}
      >
        เปลี่ยนรหัสผ่าน
      </BaseButton>
        {isModalOpen &&
        createPortal(
          <ChangePassword onClose={setIsModalOpen} />,
          document.body
        )}
        {userBankAct && (
          <BankAccount account={userBankAct.bank_accounts} className="w-full md:w-2/3 m-auto" />
        )}
        <BaseButton
          className="text-sm h-8 bg-red text-white mt-5"
          onClick={() => auth?.logOut()}
        >
          <div className="px-2">ออกจากระบบ</div>
        </BaseButton>
      </ModalContentContainer>
    </ModalContainer>
  )
}
