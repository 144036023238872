import * as React from 'react'
import { Input } from './Form'
import { BtnSecSm } from '../Button'
import * as playerService from '../../api/playerService'
import * as _ from 'lodash'
import ContactSupport from '../Footer/ContactSupport'

export default function PromotionForm({ isAuth, promotion }) {
  const [successMsg, setSuccessMsg] = React.useState()
  const [errormessage, setErrorMsg] = React.useState()

  const handleReceivePromotion = async (promotion) => {
    setErrorMsg('')
    setSuccessMsg('')

    const res = await playerService.receivePromotions(promotion.id)

    if (res.code === 409) {
      setErrorMsg(res.display_message)
    } else if (res.code === 200) {
      setSuccessMsg('รับภารกิจสำเร็จ')
    }
  }

  return (
    <div>
      <img className="mx-auto" src={`${promotion.image}`} alt="promotion" />
      <br />
      <h2 className="mb-5 text-white text-center font-bold text-xl">
        {promotion.condition_title}
      </h2>
      <Input value={promotion.description} disabled={true} />
      {!_.isNil(errormessage) && (
        <h6 className="mt-5" style={{ color: 'red' }}>
          {errormessage}
        </h6>
      )}
      {!_.isNil(successMsg) && (
        <h6 className="mt-5" style={{ color: 'green' }}>
          {successMsg}
        </h6>
      )}
      <br />
      {isAuth &&
        !promotion.is_accepted &&
        promotion.active_type === 'receive' && (
          <BtnSecSm
            shadow="true"
            className="mb-5"
            onClick={() => handleReceivePromotion(promotion)}
          >
            รับโปรโมชั่น
          </BtnSecSm>
        )}
      <ContactSupport />
    </div>
  )
}
