import React from 'react'
import { styled } from 'styled-components'
import { TitleImgHead } from './Title'
import { createPortal } from 'react-dom'
import PromotionModal from './Model/PromotionModal'
import * as _ from 'lodash'

export default function PromotionCard({ text, promotions, description, link }) {
  const [isPromotionModalOpen, setPromotionModalOpen] = React.useState(false)
  const [promotion, setPromotion] = React.useState()
  const [isAuth, setIsAuth] = React.useState()

  React.useEffect(() => {
    setIsAuth(!_.isNil(localStorage.getItem('token')))
  }, [isAuth])

  const handleSelectPromotion = async (obj) => {
    setPromotionModalOpen(true)
    setPromotion(obj)
  }

  return (
    <Box>
      <TitleImgHead>
        <h1 className="text-center text-2xl">{text}</h1>
      </TitleImgHead>
      {description && (
        <h2 className="text-white text-center text-2xl">{description}</h2>
      )}
      <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-6">
        {!_.isNil(promotions) &&
          promotions.map((obj, index) => {
            return (
              <Card key={index}>
                <ImgCard onClick={() => handleSelectPromotion(obj)}>
                  <img
                    className="h-full object-cover hover:scale-110 transition-all duration-300"
                    src={`${obj.image}`}
                    alt="promotion"
                  />
                </ImgCard>
                <h2 className="py-2 text-sm md:text-base">
                  {obj.condition_title}
                </h2>
                {obj.is_accepted && (
                  <h6 className="mt-5 absolute top-0 -right-[90px] bg-[#ff715b] py-1 text-sm w-[250px] transform rotate-45">
                    ทำอยู่
                  </h6>
                )}
              </Card>
            )
          })}
      </div>
      {isPromotionModalOpen &&
        createPortal(
          <PromotionModal
            isAuth={isAuth}
            promotion={promotion}
            onClose={setPromotionModalOpen}
          />,
          document.body
        )}
    </Box>
  )
}

const Box = styled.div.attrs({
  className:
    'p-6 display: grid margin: 20px auto justify-content: center grid-template-columns: repeat(3, 33%)',
})``

const Card = styled.div.attrs({
  className:
    'bg-gradient-to-r from-blue to-green text-center text-black rounded-lg overflow-hidden relative',
})`
  cursor: pointer;
`

const ImgCard = styled.div.attrs({
  className: 'mb-1 w-full aspect-w-1 aspect-h-1 overflow-hidden',
})`
  position: relative;
`
