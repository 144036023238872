import React from "react";
import Section from "../components/Section";
import { getGames } from "../api/gameService";

export default function Landing() {
  const [slot, setSlot] = React.useState([]);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let gameSlot = await getGames(3);
    setSlot(gameSlot);
  };

  return (
    <>
        <Section text="สล๊อตออนไลน์" games={slot} description={'บริการเดิมพันเกมสล็อตออนไลน์ยอดนิยม'} type={'slot'} />
    </>
  );
}