import { useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { playerReportAffiliate } from "../../api/playerService";
import { DateInput } from "../Form/Form";
import { BtnPrimarySm2 } from "../Button";
import { getDecodeToken } from "../../api/authService";
import moment from "moment-timezone";
import _ from "lodash";

export default function AffiliatePlayCommissionTable() {
  const user = getDecodeToken();
  const [commissions, setCommissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    username: user?.preferred_username || "",
    date_start: moment().format("YYYY-MM-DD"),
    date_end: moment().format("YYYY-MM-DD"),
    order_by_field: "turnover",
    order_by: -1,
    page: 1,
    page_size: 100,
  });

  useEffect(() => {
    handleGetAffiliateReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetAffiliateReport = async () => {
    try {
      setLoading(true);
      const response = await playerReportAffiliate({
        ...params,
        date_start: (
          new Date(`${params.date_start} 00:00:00`).getTime() / 1000
        ).toString(),
        date_end: (
          new Date(`${params.date_end} 23:59:59`).getTime() / 1000
        ).toString(),
      });
      setCommissions(response?.data || []);
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mb-4 flex justify-center gap-2">
        <DateInput
          value={params.date_start}
          onChange={(e) => {
            params.date_start = e.target?.value;
            setParams({ ...params });
          }}
        />
        <DateInput
          value={params.date_end}
          onChange={(e) => {
            params.date_end = e.target?.value;
            setParams({ ...params });
          }}
        />
        <BtnPrimarySm2 className="ml-1" onClick={handleGetAffiliateReport}>
          ค้นหา
        </BtnPrimarySm2>
      </div>
      <table className="w-full overflow-hidden">
        <thead className="bg-gradient-to-r from-blue to-green py-4">
          <tr className="text-black">
            <th></th>
            <th className="py-2">ยอด</th>
            <th>คอมมิชชั่น</th>
          </tr>
        </thead>
        <tbody className="bg-gray">
          {loading ? (
            <tr>
              <td colSpan={4} className="py-2">
                <div className="flex justify-center">
                  <MoonLoader
                    color={"#fff"}
                    size={18}
                    className="text-center"
                  />
                </div>
              </td>
            </tr>
          ) : !_.isNil(commissions) && commissions.length > 0 ? (
            commissions.map((commission, index) => {
              return (
                <tr key={index} className="text-center font-bold">
                  <td className="py-2">รวม</td>
                  <td>{commission.turnover}</td>
                  <td>{commission.commission}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4} className="py-2">
                ไม่มีข้อมูล
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
