import React from "react";
import { useParams } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { launchGame } from "../api/gameService";
import { styled } from "styled-components";

export default function LaunchGame() {
  let { id } = useParams();

  React.useEffect(() => {
    rungame();
  }, []);

  const rungame = async () => {
    const res = await launchGame(id);
    window.location.href = res;
  };

  return (
    <Container className="text-green items-center flex-col">
      <PropagateLoader
        color={"#05C973"}
        size={16}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <span className="mt-8 text-xs">กำลังโหลด</span>
    </Container>
  );
}

const Container = styled.div`
  background: url("/asset/images/bg-pattern.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2000;
  overflow: auto;
`;

// const Container = styled.div.attrs({
//     id: 'container',
// })`
// position: fixed;
// left: 0;
// top: 0;
// width: 100%;
// height: 100%;
// display: flex;
// justify-content: center;
// background-color: rgba(0, 0, 0, 0.5);
// z-index: 2000;
// transition: all 0.3s ease-in-out;
// overflow: auto;
//   `
