import { styled } from 'styled-components'

const FormWrapper = styled.form``

const Label = styled.label`
  display: block;
  line-height: 16px;
  margin-bottom: 8px;
  font-weight: 300;
  text-align: left;
  color: white;
  font-size: 14px;
  margin-top: 16px;
`

const Input = styled.input(
  ({ theme }) => `
  display: block;
  width: 100%;
  height: 42px;
  background: ${theme.colors.gray};
  border-radius: 5px;
  padding: 8px 16px;
  color: ${theme.colors.white};
  outline: none;

  &:focus  {
    border: 2px solid ${theme.colors.green};
  }

  &:disabled  {
    background: #727272;
    cursor: no-drop;
  }
`
)
const InputWithdraw = styled(Input)(
  ({ theme }) => `
  text-align: center;
`
)

const Dropdrown = styled.select(
  ({ theme }) => `
  display: block;
  width: 100%;
  height: 42px;
  background: ${theme.colors.gray};
  border-radius: 5px;
  padding: 8px 16px;
  color: ${theme.colors.white};
  margin-bottom: 16px;
  outline: none;

  &:focus  {
    border: 2px solid ${theme.colors.green};
  }
`
)

const BankSelection = styled.input(
  ({ theme }) => `
`
)

const AlertRequired = styled.p(
  ({ theme }) => `
    color: ${theme.colors.red};
    font-size: 12px;
    text-align: start;
`
)

const DateInput = styled.input.attrs({ type: 'date' })(
  ({ theme }) => `
  color: ${theme.colors.black};
  padding: 4px 12px;
  border-radius: 4px;

  &:focus {
    outline: 2px solid ${theme.colors.green};
  }
`
)

export {
  FormWrapper,
  Label,
  Input,
  Dropdrown,
  BankSelection,
  AlertRequired,
  InputWithdraw,
  DateInput,
}
