import { ButtonPrimary } from './Button'
import { styled } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSupport } from '../hook/SupportProvider';

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { lineSupport } = useSupport();

  const menu = [
    {
      name: "หน้าหลัก",
      name_en: "Home",
      pathname: "/"
    },
    {
      name: "กีฬา",
      name_en: "Sport",
      pathname: "/sport"
    },
    {
      name: "คาสิโน",
      name_en: "Casino",
      pathname: "/casino"
    },
    {
      name: "สล็อต",
      name_en: "Slot",
      pathname: "/slot"
    },
    {
      name: "หวย",
      name_en: "Lotto",
      pathname: "/lotto"
    },
    {
      name: "โปรโมชั่น",
      name_en: "Promotion",
      pathname: "/promotion"
    },
    {
      name: "วงล้อนำโชค",
      name_en: "Lucky Wheel",
      pathname: "/spin"
    },
    {
      name: "หารายได้",
      name_en: "Affiliate",
      pathname: "/affiliate"
    },
  ];

  return (
    <Box>
      {menu.map((obj, index) => {
        return <ButtonPrimary className={`border-2 mb-4 ${location.pathname === obj.pathname && 'active'}`} key={index} onClick={() => navigate(obj.pathname)}>
          <div className='flex flex-row content-center items-center'>
            <img src={`/asset/images/icon-menu/${obj.pathname === '/' ? 'home' : obj.pathname}.png`} alt="icon menu" className="w-10 h-10" />
            <div className='flex flex-col justify-start text-left ml-2'>
              <span className=''>{obj.name}</span>
              <span className='opacity-50 text-xs'>{obj.name_en.toUpperCase()}</span>
            </div>
          </div>
        </ButtonPrimary >
      })}
      <a href={lineSupport.line_url} target="_blank" className="text-center text-white text-sm font-semibold" rel="noreferrer">
        <div className='flex flex-row content-center items-center py-1.5 px-1' style={{ backgroundColor: '#00b901', borderRadius: '0.5rem' }}>
          <img src={`/asset/images/icon-line.png`} alt="icon menu" className="w-10 h-10" />
          <div className='flex flex-col justify-start text-left ml-2'>
            <span>{lineSupport.line_id}</span>
            <span>บริการ 24 ชม.</span>
          </div>
        </div>
      </a >
    </Box>
  )
}

const Box = styled.div.attrs({
  className: 'w-48 hidden md:block p-4 overflow-scroll',
})(({ theme }) => `
  background:  ${theme.colors.dark[600]};
`);