import { useEffect, useState } from "react";
import {
  ModalContainer,
  ModalContentContainerColor,
  ModalClose,
} from "./Model";
import { HiOutlineX } from "react-icons/hi";
import { styled } from "styled-components";
import {
  getListWithdraw,
  getStatus,
  withdraw,
} from "../../api/withdrawService";
import { BtnPrimarySm2 } from "../Button";
import { InputWithdraw, AlertRequired } from "../Form/Form";
import { useForm } from "react-hook-form";
import Table from "../Teble/TableList";
import ContactSupport from "../Footer/ContactSupport";
import _ from "lodash";

export default function WithdrawModal({ onClose, balance }) {
  const [listWithdraw, setListWithdraw] = useState([]);
  const [status, setStatus] = useState({});
  const [withdrawMessage, setWithdrawMessage] = useState("");
  const [isDisableWithdraw, setDisableWithdraw] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    getDafaultData();
  }, []);

  const getDafaultData = async () => {
    const res_status = await getStatus();
    setStatus(res_status);
    const list = await getListWithdraw();

    if (!_.isNil(list.withdraws)) {
      for (let index = 0; index < list.withdraws.length; index++) {
        if (_.isEqualWith(list.withdraws[index].status, "initiate")) {
          setDisableWithdraw(true);
        }
      }
      setListWithdraw(list.withdraws);
    }
  };

  const submit = async () => {
    setDisableWithdraw(true);
    const res_status = await getStatus();
    const amount_withdraw = getValues("withdraw");
    setWithdrawMessage("");
    if (!_.isNil(res_status)) {
      if (amount_withdraw < res_status.min_withdraw_credit_amount) {
        setWithdrawMessage("ถอนเงินจำนวนขั้นต่ำ 20 THB");
        setDisableWithdraw(false);
      } else if (amount_withdraw > res_status.max_withdraw_credit_amount) {
        setWithdrawMessage("ถอนเงินจำนวนสูงสุด 2500 THB");
        setDisableWithdraw(false);
      } else {
        const res = await withdraw(getValues("withdraw"));
        if (res.status === 201) {
          const list = await getListWithdraw();
          setListWithdraw(list.withdraws);

          getDafaultData();
        }
      }
    }
  };

  return (
    <ModalContainer
      onClick={(e) => {
        if (e.target.id === "modal-container") {
          onClose(false);
        }
      }}
    >
      <ModalContentContainerColor className="text-center">
        <ModalClose onClick={() => onClose(false)}>
          <HiOutlineX className="close-icon" size={24} color="#05C973" />
        </ModalClose>
        <h2 className="mb-10 text-white text-center font-bold text-xl">ถอน</h2>
        <InputWithdraw
          disabled={isDisableWithdraw || status.status === "FAILED"}
          {...register("withdraw", {
            required: "กรุณาระบุ",
            valueAsNumber: true,
            max: {
              value: balance,
              message: "จำนวนเงินมากกว่ายอดคงเหลือ",
            },
            validate: (value) => value > 0,
          })}
          aria-invalid={errors.withdraw ? "true" : "false"}
          placeholder="ระบุจำนวนเงินถอน"
          type="number"
          onChange={(event) => {
            setWithdrawMessage("");
          }}
        />
        <AlertRequired>{errors.withdraw?.message}</AlertRequired>
        <AlertRequired>{withdrawMessage}</AlertRequired>
        {status.status === "FAILED" && <Bx>{status.message}</Bx>}
        <BtnPrimarySm2
          shadow="false"
          disabled={isDisableWithdraw || status.status === "FAILED"}
          className="mt-2"
          onClick={handleSubmit(submit)}
        >
          <span className="p-3 text-xs">แจ้งถอนเงิน</span>
        </BtnPrimarySm2>
        <Text>
          ขั้นต่ำ {status.min_withdraw_credit_amount} THB สูงสุด{" "}
          {status.max_withdraw_credit_amount} THB
        </Text>
        <Table title={"ถอน"} list={listWithdraw} />
        <hr className="text-white mt-2" />
        <ContactSupport />
      </ModalContentContainerColor>
    </ModalContainer>
  );
}

const Text = styled.div.attrs({
  className: "text-center text-xs",
})(
  ({ theme }) => `
color: ${theme.colors.green};
margin-top: 1rem;
`
);

const Bx = styled.div`
  background-color: #171717;
  border: 1px solid #be2b43;
  border-radius: 8px;
  padding: 0.8rem 0.8rem;
  font-size: 14px;
  font-weight: 300;
  color: #be2b43;
  text-align: center;
  margin-top: 1rem;
`;
