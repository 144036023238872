import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Label, Input, AlertRequired } from '../Form/Form'
import { ButtonSec } from '../Button'
import { useAuth } from '../../hook/AuthProvider'
import { useForm } from 'react-hook-form'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import MoonLoader from 'react-spinners/MoonLoader'
import ForgotPassword from '../Model/ForgotPassword'
import _ from 'lodash'

export default function LoginForm() {
  const auth = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const submit = async (data) => {
    setIsLoad(true)
    const res = await auth.loginAction(data.userName, data.password)
    if (_.isUndefined(res.response)) {
      window.location.reload()
    }
    setIsLoad(false)
  }

  return (
    <div>
      <Label>ชื่อผู้ใช้หรือเบอร์โทรศัพท์</Label>
      <Input
        {...register('userName', { required: 'กรุณาระบุ' })}
        aria-invalid={errors.userName ? 'true' : 'false'}
      />
      <AlertRequired>{errors.userName?.message}</AlertRequired>

      <Label>รหัสผ่าน</Label>
      <div className="relative mb-2">
        <Input
          type={showPassword ? 'text' : 'password'}
          {...register('password', {
            required: 'กรุณาระบุ',
          })}
          aria-invalid={errors.password ? 'true' : 'false'}
        />
        <AlertRequired>{errors.password?.message}</AlertRequired>
        <div
          className="absolute top-1.5 right-2 cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <HiOutlineEyeOff color="#fff" size={18} />
          ) : (
            <HiOutlineEye color="#fff" size={18} />
          )}
        </div>
      </div>
      <ButtonSec
        className="my-4 text-lg"
        onClick={handleSubmit(submit)}
      >
        {isLoad ? (
          <MoonLoader
            color={'#fff'}
            size={14}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <span>เข้าสู่ระบบ</span>
        )}
      </ButtonSec>
      <button
        className="text-white underline"
        onClick={() => setIsModalOpen(true)}
      >
        ลืมรหัสผ่าน
      </button>

      {isModalOpen &&
        createPortal(
          <ForgotPassword onClose={setIsModalOpen} />,
          document.body
        )}
    </div>
  )
}
