import { styled } from "styled-components";

export default function Footer() {
  return (
    <Copyright>
      © 2024 1BET สงวนลิขสิทธิ์ สินค้าและบริการของ 1BET
      มีใบอนุญาตและได้รับการตรวจสอบจากรัฐบาลฟิลิปปินส์และ First Cagayan
      version 1.3.1
    </Copyright>
  );
}

const Copyright = styled.div.attrs({
  className: "px-4 lg:px-16 xl:px-36 pb-24 md:pb-6 text-xs text-center w-full",
})(({ theme }) => `
  background-color: ${theme.colors.dark[800]};
  color: ${theme.colors.text};
`);
