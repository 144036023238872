import { styled } from "styled-components";
import Footer from "./components/Footer/Footer";

export default function Main({ children }) {
  return (
    <MainContainer>
      <div className="flex-1">{children}</div>
      <Footer />
    </MainContainer>
  );
}

const MainContainer = styled.div.attrs({className: "flex flex-col flex-1 overflow-scroll pt-10",})`
  background: url("/asset/images/bg-pattern.png");
  background-size:  cover;
  background-repeat:  no-repeat;
  background-position: center center;  
  `;